.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.home-header {
  padding-top: 150px !important;
}

.home-section {
  position: relative;
  z-index: 1;
  background-color: var(--clr-bg);
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  margin-left: -1vw !important;
}

.home-content {
  padding: 9rem 2rem !important;
  color: var(--clr-txt);
  text-align: left;
}

.heading {
  font-size: 6em !important;
  padding-left: 50px !important;
  margin-top: -110px;
  margin-left: -100px;
  font-weight: 700;

}

.heading-name {
  font-size: 2.75em !important;
  width: 60vw;
  margin-left: -2.65vw;
  background-clip: text;
  -webkit-background-clip: text; /* For compatibility with some browsers */
  color: transparent !important;
  background-image: linear-gradient(94deg, #804cbb 29%, #3344dc) !important;
  margin-top: -1px;
}

.Description-long {
  font-size: 1.6em;
  justify-content: center;
  text-align: left;
  margin-left: -2.35vw;
  width: 43vw;
  margin-top: 2.5vh;
}

.Logo {
  position: relative;
  max-width: 35vw;
  margin-left: 4vw;
  margin-top: 20vh;
  border-radius: 10px;
  border: 1px solid #000;
  padding: 0px; /* Adjust the padding value to control the border spacing */
  box-sizing: border-box; /* This ensures that the padding is included in the width and height calculations */
}
.main-name {
  background-color: linear-gradient(94deg, #804cbb 29%, #3344dc) !important;
  color: linear-gradient(94deg, #804cbb 29%, #3344dc);

}

.Typewriter__wrapper {
  font-size: 2em !important;
  color: var(--clr-primary) !important;
  font-weight: 600 !important;
  padding-left: 25px;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: var(--clr-primary) !important;
}

@media (max-width: 991px) {
  .heading {
    font-size: 2.3em !important;
    padding-left: 0px !important;
    text-align: left !important;
  }

  .heading-name {
    font-size: 4em !important;
    padding-left: 0px !important;
    text-align: left !important;
  }
  .type{
    padding-left: 0px;
  }
  .Typewriter__wrapper {
    font-size: 1.3em !important;
    padding: 0px !important;
  }
  .Typewriter__cursor {
    font-size: 1.5em !important;
  }
}
@media (max-width: 767px) {
  .heading {
    font-size: 2.2em !important;
    padding-left: 20px !important;
    text-align: left !important;
  }

  .heading-name {
    font-size: 3em !important;
    padding-left: 20px !important;
    text-align: left !important;
  }
  .type{
    text-align: left !important;
  }
  .Typewriter__wrapper {
    text-align: center !important;
    font-size: 1.2em !important;
    font-weight: 400 !important;
    position: absolute;
  }

  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  background-color: var(--clr-bg-alt);
}

.home-about-description {
  color: var(--clr-txt) !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center !important;
  font: "Open-sans" !important;
}

.home-about-body {
  font-size: 4.5em !important;
  text-align: left;
  width: 75%;
  margin-top: -8vw;
  font-weight: 549.99 !important;
}

.Team-gradient {
  font-size: 4.5em;
  font-weight: bold;
  margin-left: -1vw;
  margin-top: -1vw;
  background-clip: text;
  -webkit-background-clip: text; /* For compatibility with some browsers */
  color: transparent;
  background-image: linear-gradient(94deg, #804cbb 29%, #3344dc) !important;
  width: 250px;
}

.home-about-social {
  text-align: center !important;
  padding-top: 70px;
  color: var(--clr-txt) !important;
  
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
  
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: var(--clr-bg) !important;
  box-shadow: var(--shadow) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--clr-primary);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--clr-primary);
}

.home-social-icons:hover {
  color: var(--clr-primary);
  box-shadow: 0 0 5px var(--clr-primary);
  text-shadow: 0 0 2px var(--clr-primary);
}

.social-icons {
  display: inline-block !important;
  padding-right: 10px;
  padding-left: 10px;
  color: var(--clr-primary);
}

.icon-colour {
  color: var(--clr-primary) !important;
}

.button-gradient {
  height: 56px;
  color: #B2AC88;
  margin-top: -35px;
  transform: translateY(-100px);
  transform: translateX(15px);
  margin-right: 10vw;
  background-color: transparent;
  background-image: url(https://assets.website-files.com/6225f7f47a4a271edde6f95c/64061a802b1e315f5ce590df_gradien-thing.webp);
  background-position: 50% 250%;
  background-repeat: no-repeat;
  background-size: 100%;
  border: 2px solid rgba(51, 54, 71, 0.9);
  border-radius: 50px;
  justify-content: flex-start;
  align-items: center !important;
  padding: 2px 2px;
  font-size: 32px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.5s ease-out;
  display: flex;
}
.button-gradient:hover {
  color: #fff;
  background-image: url(https://assets.website-files.com/6225f7f47a4a271edde6f95c/64061a802b1e315f5ce590df_gradien-thing.webp);
  background-position: 50% 0;
  border-color: #333647;
}
.button-gradient.black {
  width: 15em;
  color: #0a090f;
  background-image: url(https://assets.website-files.com/6225f7f47a4a271edde6f95c/64061e5a7e3df10dcc8b691d_grey-thing.webp);
  justify-content: center;
  display: flex;
}
.button-gradient.black:hover {
  background-image: url(https://assets.website-files.com/6225f7f47a4a271edde6f95c/64061e5a7e3df10dcc8b691d_grey-thing.webp);
  border-color: rgba(51, 54, 71, 0.5);
}
.button-gradient.black.white {
  color: #fff;
  background-image: url(https://assets.website-files.com/6225f7f47a4a271edde6f95c/64061a802b1e315f5ce590df_gradien-thing.webp);
}
.button-gradient.gradient2 {
  color: #0a090f;
  background-color: #fff;
  background-image: url(https://assets.website-files.com/6225f7f47a4a271edde6f95c/64061a802b1e315f5ce590df_gradien-thing.webp);
  display: flex;
}
.button-gradient.gradient2:hover {
  color: #fff;
  text-align: right;
}
.button-gradient.gradient2.desktop.blog {
  margin-top: 30px;
  padding-top: 16px;
  display: inline-block;
}
.button-gradient.gradient2.coaching {
  height: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  display: inline-block;
}
.button-gradient.gradient2.projets {
  height: auto;
  background-color: transparent;
  padding-top: 18px;
  padding-bottom: 18px;
  display: inline-block;
}
.button-gradient.process {
  flex-wrap: nowrap;
  display: inline-block;
}
.button-gradient.desktop {
  cursor: pointer;
}

.condo-pic {
  position: relative;
  max-width: 40vw;
  margin-left: -6.25vw;
  margin-top: -2vh;
  border-radius: 10px;
  border: 1px solid #000;
  padding: 0px; /* Adjust the padding value to control the border spacing */
  box-sizing: border-box; /* This ensures that the padding is included in the width and height calculations */
}


.button-gradient2 {
  height: 56px;
  width: 35vw !important;
  color: #B2AC88;
  transform: translateY(-100px);
  transform: translateX(-2px);
  margin-left: 2vw;
  background-color: transparent;
  background-image: url(https://assets.website-files.com/6225f7f47a4a271edde6f95c/64061a802b1e315f5ce590df_gradien-thing.webp);
  background-position: 50% 250%;
  background-repeat: no-repeat;
  background-size: 100%;
  border: 2px solid rgba(51, 54, 71, 0.9);
  border-radius: 50px;
  justify-content: flex-start;
  align-items: center !important;
  padding: 1px 1px;
  font-size: 32px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.5s ease-out;
  display: flex;
}
.button-gradient2:hover {
  color: #fff;
  background-image: url(https://assets.website-files.com/6225f7f47a4a271edde6f95c/64061a802b1e315f5ce590df_gradien-thing.webp);
  background-position: 50% 0;
  border-color: #333647;
}
.button-gradient2.black {
  width: 15em;
  color: #0a090f;
  background-image: url(https://assets.website-files.com/6225f7f47a4a271edde6f95c/64061e5a7e3df10dcc8b691d_grey-thing.webp);
  justify-content: center;
  display: flex;
}
.button-gradient2.black:hover {
  background-image: url(https://assets.website-files.com/6225f7f47a4a271edde6f95c/64061e5a7e3df10dcc8b691d_grey-thing.webp);
  border-color: rgba(51, 54, 71, 0.5);
}
.button-gradient2.black.white {
  color: #fff;
  background-image: url(https://assets.website-files.com/6225f7f47a4a271edde6f95c/64061a802b1e315f5ce590df_gradien-thing.webp);
}
.button-gradient2.gradient2 {
  color: #0a090f;
  background-color: #fff;
  background-image: url(https://assets.website-files.com/6225f7f47a4a271edde6f95c/64061a802b1e315f5ce590df_gradien-thing.webp);
  display: flex;
}
.button-gradient2.gradient2:hover {
  color: #fff;
  text-align: right;
}
.button-gradient2.gradient2.desktop.blog {
  margin-top: 30px;
  padding-top: 16px;
  display: inline-block;
}
.button-gradient2.gradient2.coaching {
  height: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  display: inline-block;
}
.button-gradient2.gradient2.projets {
  height: auto;
  background-color: transparent;
  padding-top: 18px;
  padding-bottom: 18px;
  display: inline-block;
}
.button-gradient2.process {
  flex-wrap: nowrap;
  display: inline-block;
}
.button-gradient2.desktop {
  cursor: pointer;
}